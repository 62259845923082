import styled from 'styled-components';
import { Body3_SemiBold, Body5_Regular } from 'styles/Global/typography';
import agreePageFamilyImg from 'assets/SungnamIcons/Img/agreePageFamilyImg.png';
import { PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { governmentAgreePageConstants } from 'constants/Government/governmentAgreePageConstants';
import { AgreeAndCodeConfirmTitle, TitleContainer } from 'styles/Global/Government/agreeAndCodeConfirmPageStyles';
import { governmentPaths } from 'constants/path';
import { MobileSelectButton } from 'components/Buttons/MobileSelectButton';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import Icon_arrow_left from 'assets/Icons/Arrow/Icon_arrow_left';
import GovernmentPersonalInformation from './GovernmentPersonalInformation';

const GovernmentAgreePage = () => {
    const navigate = useNavigate();

    const [agree, setAgree] = useState(false);
    const [agreePage, setAgreePage] = useState(false);

    const { setHeaderText, setInit } = useGovernmentHeaderStore();

    useEffect(() => {
        if (agreePage) {
            setHeaderText(
                <>
                    <ClickArea onClick={() => setAgreePage(false)}>
                        <Icon_arrow_left />
                    </ClickArea>
                    <HeaderText>약관 내용</HeaderText>
                    <EmptyArea />
                </>
            );
        } else {
            setInit();
            setAgreePage(false);
        }
    }, [agreePage]);

    const moveReadyPage = () => {
        if (agree) navigate(governmentPaths.codeConfirm);
    };

    // 확인하고 동의하기 버튼
    const AgreeButtonClick = (type: string) => {
        if (type === 'personalAgree') {
            setAgree(true);
            setAgreePage(false);
        }
    };

    // ContainerButton
    const handleContainerClick = (type: string) => {
        if (type === 'personalAgree') {
            setAgree(!agree);
        }
    };

    return (
        <>
            {agreePage ? (
                <GovernmentPersonalInformation $onClick={() => AgreeButtonClick('personalAgree')} $agree={agree} />
            ) : (
                <Layout>
                    <TitleContainer>
                        <AgreeAndCodeConfirmTitle>{governmentAgreePageConstants.title1}</AgreeAndCodeConfirmTitle>
                        <AgreeAndCodeConfirmTitle>{governmentAgreePageConstants.title2}</AgreeAndCodeConfirmTitle>
                    </TitleContainer>
                    <BodyContainer>
                        <ImgWrapper src={agreePageFamilyImg} alt='agreePage_Family_Image' />
                        <DescriptionContainer>
                            <DescTitle>{governmentAgreePageConstants.descTitle}</DescTitle>
                            <Desc>{governmentAgreePageConstants.descText2}</Desc>
                            <MobileSelectButtonWrapper>
                                <MobileSelectButton
                                    $selected={agree}
                                    $onClick={() => setAgreePage(true)}
                                    $containerClick={() => {
                                        handleContainerClick('personalAgree');
                                    }}
                                    $title='개인정보 수집 및 이용 동의'
                                    $subTitle='(필수)'
                                />
                            </MobileSelectButtonWrapper>
                        </DescriptionContainer>
                    </BodyContainer>
                    <MobileBasicButton onClick={moveReadyPage} $type='fill' disabled={!agree}>
                        {governmentAgreePageConstants.buttonText}
                    </MobileBasicButton>
                </Layout>
            )}
        </>
    );
};

export default GovernmentAgreePage;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
`;

const DescTitle = styled(Body3_SemiBold)`
    color: ${PrimaryColor.color_primary_normal};
`;
const Desc = styled(Body5_Regular)`
    color: ${TextColor.color_text_neutral};
    margin-bottom: 16px;
`;

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
`;

const ImgWrapper = styled.img`
    width: 140px;
    height: 140px;
`;

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
`;

const MobileSelectButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const ClickArea = styled.button`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
`;
const EmptyArea = styled.div`
    flex: 0 0 auto;
    width: 44px;
    height: 44px;
`;

const HeaderText = styled.span`
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
`;
