import { Dispatch, SetStateAction } from 'react';
import { NUMBER_REGEX } from 'utils/regex';

export const handleProcessEntriesErrMsgFunc = (id: string, desc: string, errObj: { [key: string]: string }, setErr: Dispatch<SetStateAction<{ [key: string]: string }>>) => {
    setErr((prevErrMsg) => ({ ...prevErrMsg, [id]: desc }));
    errObj = { ...errObj, [id]: desc };
    return errObj;
};

export const changeInputFunc = (id: string, inputValue: string, value: { [key: string]: string }, setValue: Dispatch<SetStateAction<{ [key: string]: string }>>) => {
    const numberType = ['patientMobile', 'birthDate', 'gender'];

    if (numberType.includes(id)) {
        NUMBER_REGEX.test(inputValue) && setValue({ ...value, [id]: inputValue });
    } else {
        setValue({ ...value, [id]: inputValue });
    }
};
