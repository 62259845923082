// 계정 상태 코드
export enum AccountStatus {
    pending = 0,
    active = 1,
    reject = 2,
}

// 계정 레벨 코드
export enum AccountLevel {
    default = 0,
    hospitalManager = 1,
    sungnam = 10,
    admin = 100,
    other = 101,
}

// 환자 상태 코드
export enum PatientStatus {
    default = 0,
    deleted = 1,
}

// 진료 상태 코드
export enum DiagnosisStatus {
    delete = -1,
    notAssigned = 0,
    pending = 1,
    inProgress = 2,
    completed = 3,
}

// 검사 상태 코드
export enum AssessmentStatus {
    cancel = -1,
    standBy = 0,
    inProgress = 1,
    completed = 2,
}

// message 상태 코드
export enum NotificationCode {
    addDiagnosis = '100',
    updateDiagnosis = '101',
    deleteDiagnosis = '102',
    standbyAssessment = '200',
    updateAssessment = '201',
    inProgress = '202',
    completed = '203',
    allCompleted = '204',
    updateUser = '300',
    addDoctor = '400',
    deleteDoctor = '401',
    deletePatient = '500',
    updatePatient = '501',
    createAccount = '600',
    updateAccount = '601',
    changeLevel = '602',
    deleteAccount = '603',
}
