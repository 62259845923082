import { header } from 'api/Auth/auth';
import axios from 'axios';
import { apiPaths } from 'constants/path';
import { ExistUserApiType, GetCompactListApiResopnse, SignUpApiResponseType } from 'models/SignUp/signUpApiTypes';

class SignUpApi {
    async getCompactList(): Promise<GetCompactListApiResopnse> {
        try {
            const response = await axios.get(apiPaths.compactList, {
                headers: header,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async oauth(rqData: { [key: string]: string | number | undefined }): Promise<SignUpApiResponseType> {
        try {
            const response = await axios.post(apiPaths.signUp, rqData, {
                headers: header,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async existUser(email: string): Promise<ExistUserApiType> {
        try {
            const response = await axios.get(apiPaths.existUser + email, {
                headers: header,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}
export const signUpApi = new SignUpApi();
