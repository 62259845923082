import { useEffect } from 'react';
import styled from 'styled-components';
import { font, palette, zIndex } from 'styles/Global/globalStyles';
import { useLoginStore } from 'store/useLoginDataStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { pagePaths } from 'constants/path';
import useToggle from 'hooks/CustomHooks/useToggle';
import TodayAlarmDropdown from 'components/Dropdown/TodayAlarmDropdown';
import { useAlarmListStore, useGlobalToastStore } from 'store/useToastStore';
import UnConfirmAlarmIcon from 'assets/Icons/Header/UnConfirmAlarmIcon';
import { GlobalToast } from 'components/Toast/ToastMessage';
import { MessagePayload, onMessage } from 'firebase/messaging';
import { messaging } from 'firebaseConfig';
import LeaveModal from 'components/Modal/LeaveModal';
import moment from 'moment';
import { calculateAge } from 'functions/calculateAge';
import { formattedTime } from 'functions/calculateTime';
import Logout from 'assets/Icons/Header/Logout';
import Alarm from 'assets/Icons/Alarm/Alarm';
import AlarmFill from 'assets/Icons/Alarm/AlarmFill';
import SettingUser from 'assets/Icons/Header/SettingUser';
import { logoutModalContants } from 'constants/headerConstants';
import { commonText } from 'constants/commonConstants';
import { useLeaveModalStateStore } from 'store/useLeaveModalStateStore';
import { accountCodeFilter, diagnosisAndAssessmentCodeFilter, doctorCodeFilter, patientCodeFilter, userCodeFilter } from 'functions/toastMessageCodeFilter';
import { HandleFCMFilter } from 'models/Hospital/DCKHeaderTypes';

const Header = () => {
    const { loginData } = useLoginStore();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    // const [isLogoutModal, setIsLogoutModal] = useToggle();
    const [isAlarmDropdown, setIsAlarmDropdown] = useToggle();

    const { unConfirmedAlarmList, removeOldAlarms, resetUnConfirmedAlarmList } = useAlarmListStore();
    const { setToast } = useGlobalToastStore();
    const { editState, movePageState, setIsLeaveModal, setClickType, setMovePath, setIsLogoutModal } = useLeaveModalStateStore();

    // AlarmDropdown 상태에 따른 미확인 알람 리스트 초기화
    useEffect(() => {
        isAlarmDropdown && resetUnConfirmedAlarmList();
    }, [isAlarmDropdown]);

    // fcm token filtering 및 connect, toast message 설정
    useEffect(() => {
        // 첫 렌더링 시 오늘 날짜 기준 이전 알람 삭제
        removeOldAlarms();

        const handleMessage = (event: MessageEvent) => {
            if (event.data && event.data.command === 'setToast') {
                const payload = event.data;
                // console.log('Foreground Message background:', payload, messaging);
                let toastData = {};

                if (payload.data) {
                    toastData = handleFCMFilter(payload) || {};
                }

                setToast(payload.title, payload.body, toastData);
            }
        };

        navigator.serviceWorker.addEventListener('message', handleMessage);

        // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
        return () => {
            navigator.serviceWorker.removeEventListener('message', handleMessage);
        };
    }, [messaging]);

    onMessage(messaging, (payload: MessagePayload) => {
        console.log('Foreground Message:', payload, messaging);
        let toastData = {};

        if (payload.data) {
            toastData = handleFCMFilter(payload) || {};
        }

        setToast(payload.notification!.title!, payload.notification!.body!, toastData);
    });

    // accountSetting 클릭 시 페이지 이동
    const clickAccountSetting = () => {
        if ((pathname === pagePaths.viewDetaildiagnosis && editState) || (pathname === pagePaths.addDiagnosis && movePageState)) {
            setClickType('header');
            setMovePath(pagePaths.accountSetting);
            return setIsLeaveModal();
        } else {
            navigate(pagePaths.accountSetting);
        }
    };

    // FCM 메세지 필터링
    const handleFCMFilter: HandleFCMFilter = (payload) => {
        if (!payload.data) return;

        if (diagnosisAndAssessmentCodeFilter(payload.data.code)) {
            return {
                addTime: moment().format('A h:mm'),
                diagnosisTime: formattedTime(payload.data.diagnosisDateTime!),
                patientGender: payload.data.patientGender,
                patientIdentityNumber: calculateAge(payload.data.patientIdentityNumber!).birthDate,
                patientName: payload.data.patientName,
                regNum: payload.data.regNum,
                code: payload.data.code,
            };
        } else if (userCodeFilter(payload.data.code)) {
            return {
                addTime: moment().format('A h:mm'),
                code: payload.data.code,
            };
        } else if (doctorCodeFilter(payload.data.code)) {
            return { doctorName: payload.data.doctorName, addTime: moment().format('A h:mm'), code: payload.data.code };
        } else if (patientCodeFilter(payload.data.code)) {
            return {
                regNum: payload.data.regNum,
                patientName: payload.data.patientName,
                patientGender: payload.data.patientGender,
                patientIdentityNumber: calculateAge(payload.data.patientIdentityNumber!).birthDate,
                addTime: moment().format('A h:mm'),
                code: payload.data.code,
            };
        } else if (accountCodeFilter(payload.data.code)) {
            return { userName: payload.data.userName, userLevel: payload.data.userLevel, addTime: moment().format('A h:mm'), code: payload.data.code };
        }
    };

    return (
        <Layout>
            <HeaderContainer>
                <GlobalToast />
                {isAlarmDropdown && <TodayAlarmDropdown setIsAlarmDropdown={setIsAlarmDropdown} />}
                <HospitalAndNameWrapper>
                    <Text>{loginData.hospitalName}</Text>
                    <Divide />
                    <Text>
                        {loginData.name}
                        <TextWeight>님</TextWeight>
                    </Text>
                </HospitalAndNameWrapper>
                <IconsContainer>
                    <IconWrapper onClick={() => setIsLogoutModal(true)}>
                        <Logout />
                    </IconWrapper>
                    <IconWrapper onClick={setIsAlarmDropdown}>{unConfirmedAlarmList.length !== 0 ? <UnConfirmAlarmIcon /> : !isAlarmDropdown ? <Alarm /> : <AlarmFill />}</IconWrapper>
                    {/* <IconWrapper onClick={() => navigate(pagePaths.accountSetting)}> */}
                    <IconWrapper onClick={clickAccountSetting}>
                        <SettingUser />
                    </IconWrapper>
                </IconsContainer>
            </HeaderContainer>
        </Layout>
    );
};

export default Header;

const Layout = styled.div`
    display: flex;
    position: sticky;
    top: 0;
    align-items: center;
    justify-content: right;
    height: 60px;
    width: calc(100vw - 332px);
    min-width: 950px; //1280px - 330px
    padding: 0 20px;
    background-color: ${palette.white};
    z-index: ${zIndex.basic};
`;

const HeaderContainer = styled.div`
    position: relative;
    display: flex;
    gap: 8px;
`;

const HospitalAndNameWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const Text = styled.span`
    display: flex;
    height: 19px;
    align-items: end;
    font-size: ${font.size[16]};
    font-weight: ${font.weight.semiBold};
`;

const TextWeight = styled.span`
    font-weight: ${font.weight.regular};
`;

const IconsContainer = styled.div`
    display: flex;
    gap: 2px;
`;

const IconWrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
`;

const Divide = styled.div`
    width: 1px;
    height: 12px;
    background-color: ${palette.black};
    opacity: 0.3;
`;
