import { GetDiagnosisListApiResponse, GetPatientsListApiResponse } from 'models/Hospital/hospitalApiTypes';
import { calculateAge } from './calculateAge';
import { DiagnosisStatus, PatientStatus } from 'utils/statusCode';

export const diagnosisPaginationSorting = (type: string, filterData: GetDiagnosisListApiResponse['data'] | [], direction: boolean) => {
    if (!filterData) return [];

    const sortByHospitalName = (a: { hospitalName: string }, b: { hospitalName: string }, direction: boolean) =>
        direction ? a.hospitalName.localeCompare(b.hospitalName) : b.hospitalName.localeCompare(a.hospitalName);

    const sortByPatientName = (a: { patientName: string }, b: { patientName: string }, direction: boolean) =>
        direction ? a.patientName.localeCompare(b.patientName) : b.patientName.localeCompare(a.patientName);

    const sortByPatientIdentityNumber = (a: { patientIdentityNumber: string }, b: { patientIdentityNumber: string }, direction: boolean) => {
        const birthDateA = calculateAge(a.patientIdentityNumber).birthDate;
        const birthDateB = calculateAge(b.patientIdentityNumber).birthDate;
        return direction ? birthDateA.localeCompare(birthDateB) : birthDateB.localeCompare(birthDateA);
    };

    const sortByDiagnosisDateTime = (a: { diagnosisDateTime: string }, b: { diagnosisDateTime: string }, direction: boolean) =>
        direction ? b.diagnosisDateTime.localeCompare(a.diagnosisDateTime) : a.diagnosisDateTime.localeCompare(b.diagnosisDateTime);

    const sortByStatus = (a: { status: number }, b: { status: number }, direction: boolean) => (direction ? a.status - b.status : b.status - a.status);

    const sortByDoctorName = (a: { doctorName: string }, b: { doctorName: string }, direction: boolean) =>
        direction ? a.doctorName.localeCompare(b.doctorName) : b.doctorName.localeCompare(a.doctorName);

    let sortedData;

    switch (type) {
        case 'hospitalName':
            sortedData = [...filterData].sort((a, b) => sortByHospitalName(a, b, direction));
            break;
        case 'patientName':
            sortedData = [...filterData].sort((a, b) => sortByPatientName(a, b, direction));
            break;
        case 'patientIdentityNumber':
            sortedData = [...filterData].sort((a, b) => sortByPatientIdentityNumber(a, b, direction));
            break;
        case 'diagnosisDateTime':
            sortedData = [...filterData].sort((a, b) => sortByDiagnosisDateTime(a, b, direction));
            break;
        case 'status':
            sortedData = [...filterData].sort((a, b) => sortByStatus(a, b, direction));
            break;
        case 'doctorName':
            sortedData = [...filterData].sort((a, b) => sortByDoctorName(a, b, direction));
            break;
        default:
            sortedData = filterData;
            break;
    }

    return sortedData;
};

export const managingPatientsPaginationSorting = (type: string, filterData: GetPatientsListApiResponse['data'], direction: boolean) => {
    if (!filterData) return [];

    filterData = filterData.filter((data) => data.status === PatientStatus.default);

    const sortByRegNum = (a: { regNum: string }, b: { regNum: string }) => {
        const [letterA, numberA] = a.regNum.split('-');
        const [letterB, numberB] = b.regNum.split('-');
        const letterCompare = letterA.localeCompare(letterB);
        if (letterCompare !== 0) {
            return direction ? letterCompare : -letterCompare;
        } else {
            const numA = parseInt(numberA, 10);
            const numB = parseInt(numberB, 10);
            return direction ? numA - numB : numB - numA;
        }
    };

    const sortByName = (a: { name: string }, b: { name: string }) => (direction ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name));

    const sortByIdentityNumber = (a: { identityNumber: string }, b: { identityNumber: string }) => {
        const birthDateA = calculateAge(a.identityNumber).birthDate;
        const birthDateB = calculateAge(b.identityNumber).birthDate;
        return direction ? birthDateA.localeCompare(birthDateB) : birthDateB.localeCompare(birthDateA);
    };

    const sortByLastDiagnosisTimestamp = (a: { lastDiagnosisTimestamp: number }, b: { lastDiagnosisTimestamp: number }) =>
        direction ? b.lastDiagnosisTimestamp - a.lastDiagnosisTimestamp : a.lastDiagnosisTimestamp - b.lastDiagnosisTimestamp;

    let sortedData;

    switch (type) {
        case 'regNum':
            sortedData = [...filterData].sort(sortByRegNum);
            break;
        case 'name':
            sortedData = [...filterData].sort(sortByName);
            break;
        case 'identityNumber':
            sortedData = [...filterData].sort(sortByIdentityNumber);
            break;
        case 'lastDiagnosisTimestamp':
            sortedData = [...filterData].sort(sortByLastDiagnosisTimestamp);
            break;
        default:
            sortedData = filterData;
            break;
    }

    return sortedData;
};
