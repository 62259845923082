import { NotificationCode } from 'utils/statusCode';

export const diagnosisAndAssessmentCodeFilter = (code?: string) => {
    switch (code) {
        case NotificationCode.addDiagnosis:
        case NotificationCode.updateDiagnosis:
        case NotificationCode.deleteDiagnosis:
        case NotificationCode.standbyAssessment:
        case NotificationCode.updateAssessment:
        case NotificationCode.inProgress:
        case NotificationCode.completed:
        case NotificationCode.allCompleted:
            return true;
        default:
            return false;
    }
};

export const userCodeFilter = (code?: string) => {
    switch (code) {
        case NotificationCode.updateUser:
            return true;
        default:
            return false;
    }
};

export const doctorCodeFilter = (code?: string) => {
    switch (code) {
        case NotificationCode.addDoctor:
        case NotificationCode.deleteDoctor:
            return true;
        default:
            return false;
    }
};

export const patientCodeFilter = (code?: string) => {
    switch (code) {
        case NotificationCode.deletePatient:
        case NotificationCode.updatePatient:
            return true;
        default:
            return false;
    }
};

export const accountCodeFilter = (code?: string) => {
    switch (code) {
        case NotificationCode.createAccount:
        case NotificationCode.updateAccount:
        case NotificationCode.changeLevel:
        case NotificationCode.deleteAccount:
            return true;
        default:
            return false;
    }
};
