import Icon_Warning_Fill from 'assets/Icons/Check/Icon_Warning_Fill';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import { MobileYearAndMonthSelectDropdown } from 'components/Dropdown/MobileDropdown';
import { MobileBasicInputWithLabel, MobileSemesterInputWithLabel } from 'components/Input/BasicInput';
import MobileLeaveModal from 'components/Modal/MobileLeaveModal';
import { governmentReadyPageConstants } from 'constants/Government/governmentReadyPageConstants';
import { commonErrText, commonText } from 'constants/commonConstants';
import { governmentPaths } from 'constants/path';
import { highSchool, middleSchool, primarySchool, schoolAge } from 'constants/schoolAgeAndSemester';
import { calculateYearAndMonth } from 'functions/calculateAge';
import { validateCheck } from 'functions/validationFunctions';
import useToggle from 'hooks/CustomHooks/useToggle';
import _ from 'lodash';
import { ValueType } from 'models/Common/commonTypes';
import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import styled from 'styled-components';
import { AgreeAndCodeConfirmTitle, TitleContainer } from 'styles/Global/Government/agreeAndCodeConfirmPageStyles';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { Body3_Medium_TextArea, Body4_SemiBold, Body5_Medium, Body5_Regular } from 'styles/Global/typography';
import { BasicInputLayout, InputWrapper, MobileInputLabel, MobileInputSubLabel } from 'styles/Input/inputStyle';
import { ErrorMsg } from 'styles/SignUp/signUpStyles';

const GovernmentReadyPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const errorInitValue = { email: '', patientName: '', patientMobile: '', birthDate: '', month: '', gender: '', schoolAge: '', semester: '' };
    const [value, setValue] = useState<ValueType>({
        email: '',
        patientName: '',
        patientMobile: '',
        birthDate: '',
        month: '',
        gender: '3',
        schoolAge: '',
        semester: '',
        grade: '',
        patientMemo: '',
    });
    const [errMsg, setErrMsg] = useState<ValueType>(errorInitValue);
    const [onSchoolAgeDropdown, setOnSchoolAgeDropdown] = useState(false);
    const [onSemesterDropdown, setOnSemesterDropdown] = useState(false);
    const [onYearDropdown, setOnYearDropdown] = useState(false);
    const [onMonthDropdown, setOnMonthDropdown] = useState(false);
    const [schoolAgeIdx, setSchoolAgeIdx] = useState<number | undefined>(undefined);
    const [semesterIdx, setSemesterIdx] = useState<number | undefined>(undefined);
    const [yearIdx, setYearIdx] = useState<number>();
    const [monthIdx, setMonthIdx] = useState<number>();
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const { setHeaderText, setInit } = useGovernmentHeaderStore();
    const [isLeaveModal, setIsLeaveModal] = useToggle();
    const [isBottomSheet, setIsBottomSheet] = useToggle();

    const emailInputRef = useRef<HTMLInputElement>(null);
    const patientNameInputRef = useRef<HTMLInputElement>(null);
    const patientMobileInputRef = useRef<HTMLInputElement>(null);
    const yearInputRef = useRef<HTMLInputElement>(null);
    const monthInputRef = useRef<HTMLInputElement>(null);
    const genderInputRef = useRef<HTMLInputElement>(null);
    const schoolAgeInputRef = useRef<HTMLInputElement>(null);
    const semesterInputRef = useRef<HTMLInputElement>(null);
    const memoTextAreaRef = useRef<HTMLTextAreaElement>(null);

    const yearMonth = useMemo(() => calculateYearAndMonth(), []);

    useEffect(() => {
        setHeaderText(governmentReadyPageConstants.headerText);
        setValue({ ...value, patientName: state.voucher.code });

        // 학령 / 학기 외부 클릭 시 드롭다운 닫기
        function handleClickOutside(event: { target: any }) {
            if (event.target instanceof Element && 'className' in event.target && typeof event.target.className === 'string') {
                if (event.target.className.includes('dropdown-item')) {
                    return;
                }
            }

            if (schoolAgeInputRef.current && !schoolAgeInputRef.current.contains(event.target)) {
                setOnSchoolAgeDropdown(false);
            }
            if (semesterInputRef.current && !semesterInputRef.current.contains(event.target)) {
                setOnSemesterDropdown(false);
            }
            if (yearInputRef.current && !yearInputRef.current.contains(event.target)) {
                setOnYearDropdown(false);
            }
            if (monthInputRef.current && !monthInputRef.current.contains(event.target)) {
                setOnMonthDropdown(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            setInit();
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        value.birthDate && !onMonthDropdown && handleBlurError('month');
        value.schoolAge && !onSemesterDropdown && handleBlurError('semester');
    }, [value.birthDate, onMonthDropdown, value.schoolAge, onSemesterDropdown]);

    // Next button disabled check
    useEffect(() => {
        const schoolAgeIdx0checkValue = [value.patientName, value.birthDate, value.month, value.gender, value.schoolAge, value.email, value.patientMobile];
        const checkValue = [value.patientName, value.birthDate, value.month, value.gender, value.schoolAge, value.semester, value.email, value.patientMobile];
        const errMsgCheck = [errMsg.email, errMsg.patientName, errMsg.patientMobile, errMsg.birthDate, errMsg.gender, errMsg.schoolAge, errMsg.semester];

        const checkButtonDisabled = () => {
            if (schoolAgeIdx === 0) {
                for (let value of schoolAgeIdx0checkValue) {
                    if (!value) {
                        return setButtonDisabled(true);
                    }
                }
            } else if (schoolAgeIdx !== 0) {
                for (let value of checkValue) {
                    if (!value) {
                        return setButtonDisabled(true);
                    }
                }
            }

            for (let value of errMsgCheck) {
                if (value) {
                    return setButtonDisabled(true);
                }
            }

            return setButtonDisabled(false);
        };

        checkButtonDisabled();
    }, [value, errMsg]);

    // 뒤로 가기 기능 차단
    useBlocker(({ currentLocation, nextLocation }) => {
        if (currentLocation.pathname === governmentPaths.ready && (nextLocation.pathname === governmentPaths.agree || nextLocation.pathname === governmentPaths.codeConfirm) && !isLeaveModal) {
            setIsLeaveModal();
            return true;
        }
        return false;
    });

    // input change handler
    const handleInputChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, id: string) => {
        if (id === 'patientMobile') {
            setValue({ ...value, [id]: e.target.value.replace(/[^0-9]/g, '') });
        } else if (id === 'error') {
            return;
        } else {
            setValue({ ...value, [id]: e.target.value });
        }

        if (id === 'patientMobile') {
            const phoneNumber = e.target.value.replace(/[^0-9]/g, '');
            const errMsg = validateCheck.phoneNumber(phoneNumber);

            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: errMsg }));
        } else if (id === 'email') {
            const errMsg = validateCheck.email(e.target.value);

            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: errMsg }));
            return '';
        }
    };

    // 주민번호 뒷자리(성별) 입력 및 유효성 검사
    const handleGenderChangeButton = (gender: string) => {
        setValue({ ...value, gender: gender });
    };

    // Blur 시 에러 메시지 처리
    const handleBlurError = (id: string) => {
        if (id === 'schoolAge' && schoolAgeIdx === undefined) {
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.requiredSchoolAgeAndSemester }));
        } else if (id === 'semester' && schoolAgeIdx !== 0 && semesterIdx === undefined) {
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.requiredSchoolAgeAndSemester }));
        } else if (id !== 'schoolAge' && id !== 'semester' && id !== 'birthDate' && id !== 'month' && id !== 'gender' && !value[id]) {
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.required }));
        } else if (id === 'birthDate' && !value.birthDate) {
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.requiredYearAndMonth, month: commonErrText.requiredYearAndMonth }));
        } else if (id === 'month' && !value.month) {
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.requiredYearAndMonth }));
        }
    };

    // 학령 / 학기 dropdown on/off handler
    const handleSchoolAgeAndSemesterDropDown = (id: string, idx: number | undefined, name: string, value: string) => {
        if (id === 'schoolAge' && idx === 0) {
            setValue((prevValue) => ({ ...prevValue, grade: '', schoolAge: name, semester: '' }));
            setSemesterIdx(undefined);
            setOnSchoolAgeDropdown(!onSchoolAgeDropdown);
            setSchoolAgeIdx(idx);
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, schoolAge: '', semester: '' }));
        } else if (id === 'schoolAge' && idx !== undefined) {
            setValue((prevValue) => ({ ...prevValue, grade: '', schoolAge: name, semester: '' }));
            setSemesterIdx(undefined);
            setOnSchoolAgeDropdown(!onSchoolAgeDropdown);
            setOnSemesterDropdown(true);
            setSchoolAgeIdx(idx);
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, schoolAge: '', semester: '' }));
        } else if (id === 'schoolAge') {
            setOnSchoolAgeDropdown(!onSchoolAgeDropdown);
            setOnSemesterDropdown(false);
        } else if (id === 'semester' && idx !== undefined) {
            setValue((prevValue) => ({ ...prevValue, grade: value, semester: name }));
            setOnSemesterDropdown(!onSemesterDropdown);
            setSemesterIdx(idx);
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, schoolAge: '', semester: '' }));
            // emailInputRef.current?.focus();
        } else {
            setOnSemesterDropdown(!onSemesterDropdown);
            setOnSchoolAgeDropdown(false);
        }
    };

    // 년도 / 월 dropdown on/off handler
    const handleYearAndMonthDropDown = (id: string, idx: number | undefined) => {
        if (id === 'birthDate' && idx !== undefined) {
            setValue((prevValue) => ({ ...prevValue, birthDate: String(yearMonth[idx][0]), month: '' }));
            setMonthIdx(undefined);
            setOnYearDropdown(!onYearDropdown);
            setOnMonthDropdown(true);
            setYearIdx(idx);
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: '', month: '' }));
        } else if (id === 'birthDate') {
            setOnYearDropdown(!onYearDropdown);
            setOnMonthDropdown(false);
        } else if (id === 'month' && idx !== undefined) {
            setValue((prevValue) => ({ ...prevValue, [id]: String(yearMonth[yearIdx!][1][idx]) }));
            setOnMonthDropdown(!onMonthDropdown);
            setMonthIdx(idx);
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, birthDate: '', month: '' }));
        } else {
            setOnMonthDropdown(!onMonthDropdown);
            setOnYearDropdown(false);
        }
    };

    // add diagnosis button click handler
    const clickButton = async () => {
        let err = errorInitValue;
        let checkError = false;

        const excludeValue = ['schoolAge', 'semester'];
        const processEntries = () => {
            for (let [key, keyValue] of Object.entries(value)) {
                if (!value.grade && schoolAgeIdx === undefined) {
                    setErrMsg((prevErrMsg) => ({ ...prevErrMsg, schoolAge: commonErrText.required }));
                    err = { ...err, schoolAge: commonErrText.required };
                }
                if ((key === 'schoolAge' || (key === 'semester' && semesterIdx !== undefined)) && !keyValue) {
                    setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [key]: commonErrText.requiredSchoolAgeAndSemester }));
                    err = { ...err, [key]: commonErrText.requiredSchoolAgeAndSemester };
                } else if (key === 'schoolAge' && schoolAgeIdx === undefined) {
                    setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [key]: commonErrText.requiredSchoolAgeAndSemester }));
                    err = { ...err, [key]: commonErrText.requiredSchoolAgeAndSemester };
                } else if (key === 'semester' && semesterIdx === undefined && schoolAgeIdx !== 0) {
                    setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [key]: commonErrText.requiredSchoolAgeAndSemester }));
                    err = { ...err, [key]: commonErrText.requiredSchoolAgeAndSemester };
                } else if (!keyValue && !excludeValue.includes(key)) {
                    setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [key]: commonErrText.required }));
                    err = { ...err, [key]: commonErrText.required };
                }
            }
        };

        const processCheckError = () => {
            for (let value of Object.values(errMsg)) {
                if (value) {
                    checkError = false;
                    window.scrollTo(0, 0);
                    break;
                } else {
                    checkError = true;
                }
            }
        };

        // focus 이동 함수
        const focusInput = () => {
            if (err.patientName) {
                return patientNameInputRef.current?.focus();
            } else if (err.birthDate) {
                return yearInputRef.current?.focus();
            } else if (err.gender) {
                return genderInputRef.current?.focus();
            } else if (err.schoolAge || err.semester) {
                return window.scrollTo(0, 0);
            } else if (err.email) {
                window.scrollTo(0, 0);
                return emailInputRef.current?.focus();
            } else if (err.patientMobile) {
                window.scrollTo(0, 0);
                return patientMobileInputRef.current?.focus();
            }
        };

        const navigateSelectAssessmentPage = () => {
            try {
                if (checkError) {
                    navigate(governmentPaths.selectAssessment, { replace: true, state: { value: value, info: state } });
                }
            } catch (error) {
                console.log(error);
            }
        };
        processEntries();
        processCheckError();
        focusInput();
        navigateSelectAssessmentPage();
    };

    const enterKey = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {
        if (e.key === 'Enter') {
            switch (id) {
                case 'patientName':
                    yearInputRef.current?.focus();
                    break;
                case 'birthDate':
                    genderInputRef.current?.focus();
                    break;
                case 'gender':
                    schoolAgeInputRef.current?.focus();
                    break;
                case 'email':
                    patientMobileInputRef.current?.focus();
                    break;
                case 'patientMobile':
                    memoTextAreaRef.current?.focus();
                    break;
            }
        }
    };

    // modal 나가기 버튼
    const clickLeaveButton = () => {
        setInit();
        setIsLeaveModal();
        navigate(governmentPaths.agree);
    };

    return (
        <Layout>
            {/* {isBottomSheet && <BottomSheet isBottomSheet={isBottomSheet} setIsBottomSheet={() => setIsBottomSheet()} />} */}
            {isLeaveModal && (
                <MobileLeaveModal
                    title={governmentReadyPageConstants.modalTitle1}
                    subTitle={governmentReadyPageConstants.modalTitle2}
                    content={governmentReadyPageConstants.modalContent}
                    setIsLeaveModal={setIsLeaveModal}
                    clickLeaveButtonInLeaveModal={clickLeaveButton}
                    firstButton={commonText.no}
                    secondButton={commonText.out}
                />
            )}
            <TitleAndWarningContainer>
                <TitleContainer>
                    <AgreeAndCodeConfirmTitle>{governmentReadyPageConstants.title1}</AgreeAndCodeConfirmTitle>
                    <AgreeAndCodeConfirmTitle>{governmentReadyPageConstants.title2}</AgreeAndCodeConfirmTitle>
                </TitleContainer>
                <WarningContainer>
                    <Icon_Warning_Fill />
                    <WarningDescWrapper>
                        <WarningTitle>진행 주의사항</WarningTitle>
                        <WarningDesc>반드시 자녀(아동)와 보호자(부모)가 함께 있을 때 진행해 주세요.</WarningDesc>
                    </WarningDescWrapper>
                </WarningContainer>
            </TitleAndWarningContainer>
            <BodyContainer>
                <MobileBasicInputWithLabel
                    ref={patientNameInputRef}
                    label='검사코드'
                    placeholder='검사코드'
                    onChange={handleInputChange}
                    focus={true}
                    onBlurValue={handleBlurError}
                    value={value.patientName}
                    id='patientName'
                    errorMsg={errMsg.patientName}
                    disabled={true}
                />
                <BasicInputLayout>
                    <InputWrapper>
                        <MobileInputLabel $marginBottom='0'>자녀 생년월</MobileInputLabel>
                    </InputWrapper>
                    <IdentityNumberInputWrapper>
                        <MobileYearAndMonthSelectDropdown
                            ref={yearInputRef}
                            placeholder='태어난 연도'
                            id='birthDate'
                            value={yearIdx !== undefined ? String(yearMonth[yearIdx][0]) : ''}
                            errorMsg={errMsg.birthDate}
                            yearIdx={yearIdx}
                            setSelectIdx={setYearIdx}
                            onDropdown={onYearDropdown}
                            setDropdown={handleYearAndMonthDropDown}
                            onBlur={handleBlurError}
                            yearMonth={yearMonth}
                        />
                        <MobileYearAndMonthSelectDropdown
                            ref={monthInputRef}
                            placeholder='태어난 월'
                            id='month'
                            value={yearIdx !== undefined && monthIdx !== undefined ? String(yearMonth[yearIdx][1][monthIdx]) : ''}
                            errorMsg={errMsg.month}
                            yearIdx={yearIdx}
                            monthIdx={monthIdx}
                            setSelectIdx={setMonthIdx}
                            onDropdown={onMonthDropdown}
                            setDropdown={handleYearAndMonthDropDown}
                            disabled={yearIdx === undefined}
                            yearMonth={yearMonth}
                        />
                    </IdentityNumberInputWrapper>
                    {<ErrorMsg $marginTop='0'>{errMsg.birthDate || errMsg.month}</ErrorMsg>}
                </BasicInputLayout>
                <BasicInputLayout>
                    <InputWrapper>
                        <MobileInputLabel $marginBottom='0'>자녀 성별</MobileInputLabel>
                    </InputWrapper>
                    <IdentityNumberInputWrapper>
                        <MobileBasicButton $type={value.gender === '3' ? 'fill' : 'outLine'} onClick={() => handleGenderChangeButton('3')}>
                            남성
                        </MobileBasicButton>
                        <MobileBasicButton $type={value.gender === '4' ? 'fill' : 'outLine'} onClick={() => handleGenderChangeButton('4')}>
                            여성
                        </MobileBasicButton>
                    </IdentityNumberInputWrapper>
                </BasicInputLayout>
                <IdentityNumberInputWrapper>
                    <MobileSemesterInputWithLabel
                        ref={schoolAgeInputRef}
                        label='자녀 학령/학기'
                        placeholder='학령'
                        id='schoolAge'
                        value={schoolAgeIdx !== undefined ? schoolAge[schoolAgeIdx] : ''}
                        errorMsg={errMsg.schoolAge || errMsg.semester}
                        onDropdown={onSchoolAgeDropdown}
                        setDropdown={handleSchoolAgeAndSemesterDropDown}
                        schoolAgeIdx={schoolAgeIdx}
                        onBlur={handleBlurError}
                    />
                    <MobileSemesterInputWithLabel
                        ref={semesterInputRef}
                        label=''
                        placeholder='학기'
                        id='semester'
                        value={
                            semesterIdx !== undefined && schoolAgeIdx === 1
                                ? primarySchool[semesterIdx].semester
                                : semesterIdx !== undefined && schoolAgeIdx === 2
                                ? middleSchool[semesterIdx].semester
                                : semesterIdx !== undefined && schoolAgeIdx === 3
                                ? highSchool[semesterIdx].semester
                                : ''
                        }
                        errorMsg={errMsg.schoolAge || errMsg.semester}
                        setDropdown={handleSchoolAgeAndSemesterDropDown}
                        onDropdown={onSemesterDropdown}
                        schoolAgeIdx={schoolAgeIdx}
                        semesterIdx={semesterIdx}
                        onBlur={handleBlurError}
                    />
                </IdentityNumberInputWrapper>
                <MobileBasicInputWithLabel
                    ref={patientMobileInputRef}
                    label='보호자 연락처'
                    desc='검사 후 상담을 위해 연락처를 입력해주세요.'
                    placeholder='상담 받으실 연락처 (숫자만 입력)'
                    onChange={handleInputChange}
                    id='patientMobile'
                    value={value.patientMobile}
                    errorMsg={errMsg.patientMobile}
                    onBlur={() => handleBlurError('patientMobile')}
                    onKeyDown={enterKey}
                />
                <MobileBasicInputWithLabel
                    ref={emailInputRef}
                    label='보호자 이메일'
                    desc='검사 결과지 전송을 위해 이메일 주소를 입력해주세요.'
                    placeholder='결과지 받으실 이메일 주소'
                    onChange={handleInputChange}
                    onBlurValue={handleBlurError}
                    value={value.email}
                    id='email'
                    errorMsg={errMsg.email}
                    onKeyDown={enterKey}
                />
                <BasicInputLayout>
                    <InputWrapper>
                        <MobileInputLabel $marginBottom='0'>
                            메모 <SubLabel>(선택)</SubLabel>
                        </MobileInputLabel>
                        <MobileInputSubLabel $marginBottom='4px'>상담 전문가에게 전달할 메모를 남겨주세요.</MobileInputSubLabel>
                        <Textarea
                            ref={memoTextAreaRef}
                            value={value.patientMemo}
                            onChange={(e) => handleInputChange(e, 'patientMemo')}
                            placeholder='상담 가능한 시간과 전달하고 싶은 내용을 자유롭게 남겨주세요.'
                        />
                    </InputWrapper>
                </BasicInputLayout>
            </BodyContainer>
            <MobileBasicButton onClick={clickButton} $type='fill' disabled={buttonDisabled}>
                {governmentReadyPageConstants.bottonText}
            </MobileBasicButton>
        </Layout>
    );
};

export default GovernmentReadyPage;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 32px;
`;

const IdentityNumberInputWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const TitleAndWarningContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
`;

const WarningContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 4px;
    padding: 12px 16px 14px 12px;
    background-color: ${BackgroundColor.color_background_warning};
    border-radius: ${Radius.radius_8};
`;

const WarningDescWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 1px;
`;

const WarningTitle = styled(Body4_SemiBold)``;

const WarningDesc = styled(Body5_Medium)``;

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 36px;
`;

const Textarea = styled(Body3_Medium_TextArea)`
    display: flex;
    alignitems: flex-start;
    width: 100%;
    height: 180px;
    padding: 14px 16px;
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: ${Radius.radius_8};
    // letter-spacing: -0.5px;
    resize: none;
    outline: none;
    &:focus :hover {
        background-color: ${BackgroundColor.color_background_white};
    }

    &:hover {
        border: 1px solid ${BorderColor.color_border_normal_hover};
    }

    &:disabled {
        border: 1px solid ${BorderColor.color_border_normal};
        background-color: ${BackgroundColor.color_background_disabled};
        color: ${TextColor.color_text_normal};

        &:hover {
            background-color: inherit;
        }
    }

    &:focus {
        border: 1px solid ${BorderColor.color_border_normal_press};
    }
    &::placeholder {
        color: ${TextColor.color_text_placeholder};
    }
`;

const SubLabel = styled(Body5_Regular)`
    color: ${TextColor.color_text_weak};
`;
