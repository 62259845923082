import BasicButton from 'components/Buttons/BasicButton';
import { HospitalNameInputWithLabel, InputWithLabel } from 'components/Input/InputWithLabel';
import useToggle from 'hooks/CustomHooks/useToggle';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { InputContainer, PageLayout, PageTitle } from 'styles/Global/commonStyles';
import { getJamo } from './getJamo';
import { useNavigate } from 'react-router-dom';
import { pagePaths } from 'constants/path';
import CompleteSignUp from './CompleteSignUp';
import { signUpApi } from 'api/SignUp/signUpApi';
import { ButtonContainer } from 'styles/Global/buttonStyles';
import { ValueType } from 'models/Common/commonTypes';
import { validateCheck } from 'functions/validationFunctions';
import { GetCompactList } from 'models/Hospital/hospitalApiTypes';
import { signUpApiCall } from 'functions/apiFunctions';
import { commonErrText, commonText } from 'constants/commonConstants';
import { useLoadingStore } from 'store/useLoadingStore';
import LeaveModal from 'components/Modal/LeaveModal';
import _ from 'lodash';
import { signUpButtonText, signUpDescriptionText, signUpErrText, signUpLeaveModalText, signUpPlaceholderText } from 'constants/signUpPageConstants';
import { SPACE_REGEX } from 'utils/regex';
import Copyright from 'components/Common/Copyright';
import { handleProcessEntriesErrMsgFunc } from 'functions/inputFunction';

const SignUp = () => {
    const initValue = { email: '', password: '', confirmPassword: '', hospitalName: '', name: '', phoneNumber: '' };

    const [value, setValue] = useState<ValueType>(initValue);
    const [errMsg, setErrMsg] = useState<ValueType>(initValue);
    const [selectedHospital, setSelectedHospital] = useState<GetCompactList>();
    const [onHospitalListDropdown, setOnHospitalListDropdown] = useState(false);
    const [filteredData, setFilteredData] = useState<GetCompactList[]>([]);
    const [signUpComplete, setSignUpComplete] = useState(false);
    const [hospitalList, getHospitalList] = useState<GetCompactList[]>([]);

    const [isPasswordVisible, setIsPasswordVisible] = useToggle();

    const hospitalNameRef = useRef<HTMLDivElement>(null);
    const hospitalNameInputRef = useRef<HTMLInputElement>(null);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const confirmPasswordInputRef = useRef<HTMLInputElement>(null);
    const nameInputRef = useRef<HTMLInputElement>(null);
    const phoneNumberInputRef = useRef<HTMLInputElement>(null);

    const { setLoading } = useLoadingStore();

    const [isLeaveModal, setIsLeaveModal] = useToggle();

    const navigate = useNavigate();

    // hospital 자모음 분리 및 search function
    useEffect(() => {
        if (value.hospitalName) {
            const disassembledName = getJamo(value.hospitalName);
            setFilteredData(hospitalList?.filter((data) => getJamo(data.name).includes(disassembledName)));
        } else {
            setFilteredData(hospitalList);
        }
    }, [value.hospitalName]);

    // page 진입 시 병원 리스트 불러오기
    useEffect(() => {
        getCompactList();
        function handleClickOutside(event: { target: any }) {
            if (hospitalNameRef.current && !hospitalNameRef.current.contains(event.target)) {
                setOnHospitalListDropdown(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (onHospitalListDropdown && errMsg.hospitalName) {
            return hospitalNameInputRef.current?.focus();
        }
    }, [onHospitalListDropdown]);

    // 간략한 병원 정보 불러오기 api
    const getCompactList = async () => {
        try {
            const response = await signUpApi.getCompactList();

            if (response.status === 'ok') {
                const sortedData = [...response.data].sort((a, b) => a.name.localeCompare(b.name));
                return getHospitalList(sortedData);
            } else {
                throw new Error('읽기 실패');
            }
        } catch (error) {
            throw error;
        }
    };

    // input change and real time error check function
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        if (id === 'phoneNumber') {
            setValue({ ...value, [id]: e.target.value.replace(/[^0-9]/g, '') });
        } else if (id === 'error') {
            return;
        } else {
            setValue({ ...value, [id]: e.target.value });
        }

        if (id !== 'hospitalName') {
            const error = validateCheck[id](e.target.value, id === 'password' || id === 'confirmPassword' ? value : '', setErrMsg);
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: error }));
        }
    };

    // blur error check function
    const handleBlurError = (id: string) => {
        if (id === 'hospitalName') {
            if (!value[id]) return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: signUpErrText.selectHospitalErr }));
            const hospitalCheck = hospitalList.filter((data) => data.name === value.hospitalName);
            if (hospitalCheck.length === 0) return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: signUpErrText.selectHospitalErr }));
        } else if (id === 'confirmPassword' && !value['confirmPassword']) {
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, confirmPassword: signUpErrText.requiredConfirmPasswordErr }));
        } else if (!value[id]) {
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.required }));
        }
    };

    const saveHospitalData = (data: GetCompactList) => {
        setValue((prevValue) => ({ ...prevValue, hospitalName: data.name }));
        setErrMsg((prevErrMsg) => ({ ...prevErrMsg, hospitalName: '' }));
        setSelectedHospital(data);
        setOnHospitalListDropdown(false);
    };

    const changeToInitValue = () => {
        setValue((prevValue) => ({ ...prevValue, hospitalName: '' }));
        setSelectedHospital(undefined);
        setOnHospitalListDropdown(true);

        setErrMsg((prevErrMsg) => ({ ...prevErrMsg, hospitalName: signUpErrText.selectHospitalErr }));
    };

    // TODO: 수정 필요
    // signup page button click function
    const clickButton = (type: string) => {
        setLoading(true);
        let err: ValueType = { ...initValue };

        if (type === 'cancel') {
            const valueLength = _.find(value, (v) => v.length > 0 && SPACE_REGEX.test(v) === false);
            setLoading(false);
            if (valueLength) {
                return setIsLeaveModal();
            } else {
                navigate(pagePaths.login);
            }
        } else if (type === 'submit') {
            let checkError = false;

            const processEntries = () => {
                for (let [key, keyValue] of Object.entries(value)) {
                    if (!keyValue) {
                        switch (key) {
                            case 'confirmPassword': {
                                err = handleProcessEntriesErrMsgFunc(key, signUpErrText.requiredConfirmPasswordErr, err, setErrMsg);
                                break;
                            }
                            case 'hospitalName': {
                                err = handleProcessEntriesErrMsgFunc(key, signUpErrText.selectHospitalErr, err, setErrMsg);
                                break;
                            }
                            default: {
                                err = handleProcessEntriesErrMsgFunc(key, commonErrText.required, err, setErrMsg);
                                break;
                            }
                        }
                    }
                }
            };

            const processCheckError = () => {
                for (let value of Object.values(err)) {
                    if (value) {
                        checkError = false;
                        window.scrollTo(0, 0);
                        setLoading(false);
                        break;
                    } else {
                        checkError = true;
                    }
                }
            };

            const focusInput = () => {
                switch (true) {
                    case err.email.length !== 0:
                        return emailInputRef.current?.focus();
                    case err.password.length !== 0:
                        return passwordInputRef.current?.focus();
                    case err.confirmPassword.length !== 0:
                        return confirmPasswordInputRef.current?.focus();
                    case err.hospitalName.length !== 0:
                        return hospitalNameInputRef.current?.focus();
                    case err.name.length !== 0:
                        return nameInputRef.current?.focus();
                    case err.phoneNumber.length !== 0:
                        return phoneNumberInputRef.current?.focus();
                }
            };

            const processApiCall = async () => {
                try {
                    if (checkError) {
                        await signUpApiCall(value, selectedHospital, setSignUpComplete, setErrMsg, setLoading, emailInputRef);
                    }
                } catch (error) {
                    setLoading(false);
                    console.log(error);
                }
            };

            processEntries();
            focusInput();
            processCheckError();
            processApiCall();
        }
    };

    return (
        <PageLayout>
            {signUpComplete ? (
                <CompleteSignUp />
            ) : (
                <InnerLayout>
                    {isLeaveModal && (
                        <LeaveModal
                            title={signUpLeaveModalText.title}
                            content={signUpLeaveModalText.content}
                            firstButton={commonText.stay}
                            secondButton={commonText.out}
                            setIsLeaveModal={setIsLeaveModal}
                            clickLeaveButtonInLeaveModal={() => navigate(pagePaths.login)}
                        />
                    )}
                    <PageTitle>회원가입</PageTitle>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            clickButton('submit');
                        }}
                    >
                        <InputContainer $minheight='782px' $marginbottom='52px' $margintop='40px'>
                            <InputWithLabel
                                ref={emailInputRef}
                                label={commonText.emailId}
                                desc={signUpDescriptionText.inputEmail}
                                placeholder={signUpPlaceholderText.id}
                                onChange={handleInputChange}
                                id='email'
                                focus={true}
                                value={value.email}
                                errorMsg={errMsg.email}
                                onBlur={handleBlurError}
                            />
                            <InputWithLabel
                                ref={passwordInputRef}
                                label={commonText.password}
                                placeholder={signUpPlaceholderText.passwordCondition}
                                onChange={handleInputChange}
                                id='password'
                                value={value.password}
                                errorMsg={errMsg.password}
                                isPasswordVisible={isPasswordVisible}
                                setIsPasswordVisible={setIsPasswordVisible}
                                onBlur={handleBlurError}
                            />
                            <InputWithLabel
                                ref={confirmPasswordInputRef}
                                label={commonText.confirmPassword}
                                placeholder={commonText.confirmPassword}
                                onChange={handleInputChange}
                                id='confirmPassword'
                                value={value.confirmPassword}
                                errorMsg={errMsg.confirmPassword}
                                isPasswordVisible={isPasswordVisible}
                                onBlur={handleBlurError}
                            />
                            <HospitalNameInputWithLabel
                                label={commonText.hospitalName}
                                desc={signUpDescriptionText.inputHospitalName}
                                placeholder={signUpPlaceholderText.searchHospital}
                                onChange={handleInputChange}
                                id='hospitalName'
                                value={value.hospitalName}
                                errorMsg={errMsg.hospitalName}
                                onFocus={() => setOnHospitalListDropdown(true)}
                                onDropdown={onHospitalListDropdown}
                                hospitalData={!value.hospitalName && filteredData.length === 0 ? hospitalList : filteredData}
                                onClick={saveHospitalData}
                                selectedHospital={selectedHospital}
                                changeToInitValue={changeToInitValue}
                                hospitalNameRef={hospitalNameRef}
                                hospitalNameInputRef={hospitalNameInputRef}
                                onBlur={handleBlurError}
                            />
                            <InputWithLabel
                                ref={nameInputRef}
                                label={commonText.name}
                                desc={signUpDescriptionText.inputDoctor}
                                placeholder={commonText.name}
                                onChange={handleInputChange}
                                id='name'
                                value={value.name}
                                errorMsg={errMsg.name}
                                onBlur={handleBlurError}
                            />
                            <InputWithLabel
                                ref={phoneNumberInputRef}
                                label={commonText.phoneNumber}
                                desc={signUpDescriptionText.inputPhoneNumber}
                                placeholder={signUpPlaceholderText.phoneNumberCondition}
                                onChange={handleInputChange}
                                id='phoneNumber'
                                value={value.phoneNumber}
                                errorMsg={errMsg.phoneNumber}
                                onBlur={handleBlurError}
                            />
                        </InputContainer>
                        <ButtonContainer>
                            <BasicButton
                                $width='120px'
                                $type='anotherFill'
                                buttonType='button'
                                onClick={() => {
                                    clickButton('cancel');
                                }}
                            >
                                {commonText.cancel}
                            </BasicButton>
                            <BasicButton $width='310px' $type='fill' buttonType='submit'>
                                {signUpButtonText.submitSignUp}
                            </BasicButton>
                        </ButtonContainer>
                    </form>
                    <Copyright />
                </InnerLayout>
            )}
        </PageLayout>
    );
};

export default SignUp;

const InnerLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 440px;
    margin-top: 86px;
`;
