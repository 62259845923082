import React from 'react';
import styled from 'styled-components';

import { ChildrenType } from 'models/Common/commonTypes';
import { font, palette, zIndex } from 'styles/Global/globalStyles';
import { Body1_Bold, Body2_Regular, Body3_Regular, Body4_Regular, Title2_Bold } from 'styles/Global/typography';
import { TextColor } from 'styles/Global/colorSemantic';

interface ModalProps extends ChildrenType {
    $width?: string;
    $gap?: string;
    $fontSize?: string;
    $fontWeight?: number;
    $height?: string;
    $padding?: string;
    $type?: string;
}

export const ModalBackground = ({ children, $type }: ModalProps) => {
    return <ModalBackgroundWrapper $type={$type}>{children}</ModalBackgroundWrapper>;
};

export const ModalLayout = ({ children, $width, $height, $gap, $padding }: ModalProps) => {
    return (
        <ModalLayoutWrapper $width={$width} $height={$height} $gap={$gap} $padding={$padding}>
            {children}
        </ModalLayoutWrapper>
    );
};
export const MobileModalLayout = ({ children, $width, $height, $gap, $padding }: ModalProps) => {
    return (
        <MobileModalLayoutWrapper $width={$width} $height={$height} $gap={$gap} $padding={$padding}>
            {children}
        </MobileModalLayoutWrapper>
    );
};

export const ModalTitle = ({ children }: ModalProps) => {
    return <ModalTitleWrapper>{children}</ModalTitleWrapper>;
};
export const ModalLeftTitle = ({ children }: ModalProps) => {
    return <ModalLeftTitleWrapper>{children}</ModalLeftTitleWrapper>;
};
export const MobileModalLeftTitle = ({ children }: ModalProps) => {
    return <MobileModalLeftTitleWrapper>{children}</MobileModalLeftTitleWrapper>;
};

export const ModalTitleAndContent = ({ children }: ModalProps) => {
    return <ModalTitleAndContentContainer>{children}</ModalTitleAndContentContainer>;
};

export const ModalContent = ({ children }: ModalProps) => {
    return <ModalContentWrapper>{children}</ModalContentWrapper>;
};
export const MobileModalContent = ({ children }: ModalProps) => {
    return <MobileModalContentWrapper>{children}</MobileModalContentWrapper>;
};

export const ModalFooter = ({ children }: ChildrenType) => {
    return <ModalFooterWrapper>{children}</ModalFooterWrapper>;
};

export const ModalCenterButtonFooter = ({ children }: ChildrenType) => {
    return <ModalCenterButtonWrapper>{children}</ModalCenterButtonWrapper>;
};

const ModalTitleAndContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 12px;
`;

const ModalBackgroundWrapper = styled.div<{ $type?: string }>`
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    min-width: ${(props) => !props.$type && '1280px'};
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: ${zIndex.dim};
`;

const ModalLayoutWrapper = styled.section<ModalProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: ${(props) => props.$width};
    height: ${(props) => props.$height};
    padding: ${(props) => props.$padding || '40px 36px 32px 36px'};
    border-radius: 12px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
    z-index: ${zIndex.modalPopup};
    background-color: ${palette.white};
    gap: ${(props) => props.$gap || '36px'};
`;

const MobileModalLayoutWrapper = styled.section<ModalProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: ${(props) => props.$width};
    height: ${(props) => props.$height};
    padding: ${(props) => props.$padding || '26px 24px 24px'};
    border-radius: 16px;
    box-shadow: 0px 4px 20px 0px rgba(16, 16, 16, 0.2);
    z-index: ${zIndex.modalPopup};
    background-color: ${palette.white};
    gap: ${(props) => props.$gap || '20px'};
`;

const ModalTitleWrapper = styled(Title2_Bold)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
const ModalLeftTitleWrapper = styled(Title2_Bold)`
    display: flex;
    align-items: center;
    width: 100%;
`;
const MobileModalLeftTitleWrapper = styled(Body1_Bold)`
    display: flex;
    align-items: center;
    width: 100%;
`;

const ModalContentWrapper = styled(Body3_Regular)`
    text-align: center;
    width: 410px;
    color: ${TextColor.color_text_neutral};
`;
const MobileModalContentWrapper = styled(Body4_Regular)`
    width: 100%;
    color: ${TextColor.color_text_neutral};
`;

const ModalFooterWrapper = styled.div`
    display: flex;
    gap: 8px;
`;

const ModalCenterButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 10px;
`;
